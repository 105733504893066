import {Market} from "@/components/Perps/types";
import {USDValue} from "@/components/Perps/USDValue";
import {formatUnits} from "viem";
import {EthValue} from "@/components/Perps/EthValue";
import React from "react";
import {isUsdToken} from "@/util/chainConstants";

export interface Props {
  id?: string;
  value: bigint;
  valueForPercent?: bigint;
  original?: bigint;
  fractions?: number | undefined;
  market: Market;
  className?: string;
  showPlus?: boolean;
  valueClassName?: string
}

export const MarketValueView = (props: Props) => {
  const {id, market, value, valueForPercent, original, fractions, className, showPlus, valueClassName} = props;
  const quoteToken = market.pair.quoteToken;
  const quoteIsUsd = isUsdToken(quoteToken.address);

  const format = (v?: bigint) => {
    if (v === undefined) {
      return undefined;
    }
    return Number(formatUnits(v, quoteToken.decimals));
  }

  return (
    <>
      {
        quoteIsUsd
          ? <USDValue
              id={id}
              className={className}
              value={format(value)!}
              original={format(original)}
              valueForPercent={format(valueForPercent)}
              valueClassName={valueClassName}
              showPlus={showPlus} />
          : <EthValue
              id={id}
              className={className}
              value={BigInt(value)}
              original={original}
              fractions={fractions}
              valueForPercent={valueForPercent}
              valueClassName={valueClassName}
              showPlus={showPlus}/>
      }
    </>
  )
}