import classNames from "classnames";
import {GoTriangleDown, GoTriangleUp} from "react-icons/go";
import React from "react";
import {twMerge} from "tailwind-merge";
import useId from "@/hooks/useId";

export interface Props {
  value: number;
  valueForPercent?: number;
  original?: number;
  showColor?: boolean;
  showPlus?: boolean;
  className?: string;
  id?: string;
  valueClassName?: string
}

export const USDValue = ({id, value, valueForPercent, original, showColor, showPlus, className, valueClassName}: Props) => {
  const divId = useId(id);

  let percentValue: number | undefined;

  const valueToUse = valueForPercent !== undefined ? valueForPercent : value;

  if (original) {
    percentValue = (valueToUse / original - 1) * 100;
  }

  const renderPercentValue = () => {
    if (percentValue === undefined) {
      return null;
    }
    return (
      <span className={classNames("flex items-center text-xs", {
        "text-neutral-content": percentValue === 0,
        "text-call": percentValue > 0,
        "text-put": percentValue < 0
      })}>
        {percentValue === 0 ? <GoTriangleDown/> : percentValue > 0 ? <GoTriangleUp/> : <GoTriangleDown/>}
        {parseFloat(Math.abs(percentValue).toFixed(1))}%
      </span>
    )
  }

  return (
    <div id={divId} className={twMerge(classNames("flex flex-row gap-1 items-center", {
      "text-call": showColor && value > 0,
      "text-put": showColor && value < 0,
      "text-neutral-content": showColor && value === 0,
    }), className || '')}>
      <div className={twMerge("flex flex-row items-center gap-1", valueClassName || '')}>
        {showPlus && value > 0 && '+'}
        {value.toLocaleString([], {style: "currency", currency: "USD", maximumFractionDigits: 1})}
      </div>
      {renderPercentValue()}
    </div>
)
}