import {ImSearch} from "react-icons/im";
import {numberSort, Table, TableColumn} from "@/components/Table";
import React, {ClipboardEvent, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {fetchMarketStatsList} from "@/api/perpsDataFetcher";
import {Market, MarketStatsList} from "@/components/Perps/types";
import classNames from "classnames";
import {Timeframe, useUserSelection} from "@/contexts/UserSelectionContext";
import {useHasMounted} from "@/hooks/useHasMounted";
import {useRouter} from "next/router";
import {CHAIN_ID, defaultMarketName, getBaseUrl} from "@/util/constants";
import {
  getBackendUrl,
  getChainImage,
  getChainName,
  isUsdToken,
  isWethToken
} from "@/util/chainConstants";
import {useAccount} from "wagmi";
import {prettifyNumber} from "@/util/converters";
import {useStringQueryParam} from "@/hooks/useQueryParam";
import {useEthPrice} from "@/contexts/EthPriceContext";
import {Tabs} from "@/components/Tabs";
import {Dropdown, DropdownOption} from "@/components/Dropdown";
import {useIsDesktop} from "@/hooks/useIsDesktop";
import {parseUnits} from "viem";
import {twMerge} from "tailwind-merge";
import {Tooltip as ReactTooltip} from "react-tooltip";
import {blast} from "@/util/additionalChains";
import {mainnet, sepolia} from "wagmi/chains";
import {MarketPriceView} from "@/components/Perps/market/MarketPriceView";
import {MarketValueView} from "@/components/Perps/market/MarketValueView";
import {TradeRewardsView} from "@/components/Perps/TradeRewardsView";

const priorityTokens = ["USDB", "WBTC", "SOL", "BLAST", "Mog", "NEIRO"].reverse();

export interface Props {
  onSelected?: (market: Market) => any | undefined;
  showTokensOnly?: boolean;
  columnsToExclude?: string[];
  className?: string | undefined;
  displayChainSelector?: boolean;
}

const getChainDropdown = (chainId: number) => {
  return {
    label: getChainName(chainId),
    value: chainId,
    icon: getChainImage(chainId)
  } as DropdownOption<number>;
}

const options: DropdownOption<Timeframe>[] = [
  {label: "1H ", value: 1},
  {label: "4H ", value: 4},
  {label: "12H ", value: 12},
  {label: "24H ", value: 24}
];

const selections: DropdownOption<number>[] =[
  {label: "All Chains", value: 0, icon: "/static/wasabi_logo_small.svg"}
];
if (CHAIN_ID === sepolia.id) {
  selections.push(getChainDropdown(sepolia.id));
} else {
  selections.push(getChainDropdown(blast.id));
  selections.push(getChainDropdown(mainnet.id));
}

const defaultSort = (a: MarketStatsList, b: MarketStatsList, ethPrice: number) => {
  if (a.boost && b.boost) {
    return b.boost.boost - a.boost.boost;
  } else if (a.boost) {
    return -1;
  } else if (b.boost) {
    return 1;
  }
  const aPriority = priorityTokens.indexOf(a.market.name);
  const bPriority = priorityTokens.indexOf(b.market.name);
  if (aPriority >= 0 && bPriority >= 0) {
    return bPriority - aPriority;
  } else if (aPriority >= 0) {
    return -1;
  } else if (bPriority >= 0) {
    return 1;
  }
  let aOpenInterest = a.openInterest.longOpenInterest.amount + a.openInterest.shortOpenInterest.amount;
  if (isUsdToken(a.market.quoteTokenAddress)) {
    aOpenInterest /= ethPrice;
  }
  let bOpenInterest = b.openInterest.longOpenInterest.amount + b.openInterest.shortOpenInterest.amount;
  if (isUsdToken(b.market.quoteTokenAddress)) {
    bOpenInterest /= ethPrice;
  }
  if (aOpenInterest > 0 && bOpenInterest > 0) {
    return bOpenInterest - aOpenInterest;
  } else if (aOpenInterest > 0) {
    return -1;
  } else if (bOpenInterest > 0) {
    return 1;
  }
  return b.tokenStats.oneDayVolumeUsd - a.tokenStats.oneDayVolumeUsd;
}


export const TokensTable = ({onSelected, showTokensOnly, columnsToExclude, className, displayChainSelector = true}: Props) => {
  const {address} = useAccount();
  const isDesktop = useIsDesktop();
  const {ethPrice} = useEthPrice();
  const hasMounted = useHasMounted();
  const router = useRouter();
  const {userSelections, handleSelection} = useUserSelection();
  const [searchText, setSearchText] = useState<string>("");
  const [marketName, setMarketName] = useStringQueryParam('market', userSelections.market || defaultMarketName);

  const filterSearch = (marketStats: MarketStatsList) => {
    if (searchText.length === 0) {
      return true;
    }
    return marketStats.market.name.toLowerCase().includes(searchText.toLowerCase()) || marketStats.market.name.toLowerCase().includes(searchText.toLowerCase());
  }

  const [selectedChain, setSelectedChain] = useState(displayChainSelector ? 0 : CHAIN_ID);

  const query = useQuery({
    queryKey: ['market_stats_list'],
    queryFn: async () => {
      const data = await fetchMarketStatsList();
      const otherChains = selections.filter(s => s.value !== 0 && s.value !== CHAIN_ID);
      for (const chain of otherChains) {
        const otherData = await fetchMarketStatsList(getBackendUrl(chain.value));
        data.items.push(...(otherData.items || []));
      }
      return data;
    },
    refetchInterval: 20 * 1000, // 20 seconds
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setSearchText(inputValue);
  };

  const handleInputPaste = (event: ClipboardEvent<HTMLInputElement>) => {
    const pastedText = event.clipboardData.getData("text");
    event.preventDefault();
    setSearchText(pastedText);
  };

  const filteredOptions = query.data?.items
    .filter(i => selectedChain === 0 || i.market.chainId === selectedChain)
    .filter(filterSearch)
    .sort((a, b) => defaultSort(a, b, ethPrice || 2500));

  const handleRowClick = (data: MarketStatsList) => {
    if (CHAIN_ID === data.market.chainId) {
      onSelected && onSelected(data.market);
      setMarketName(data.market.name);
    } else {
      const url = `${getBaseUrl(data.market.chainId)}/?t=active&side=long&market=${marketName}`;
      router.push(url);
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (hasMounted && router.isReady && event.key === "Enter" && filteredOptions && filteredOptions.length > 0) {
      const selectedToken = filteredOptions[0];
      handleRowClick(selectedToken);
    }
  };

  const getValue = (value: "volume" | "change", token: MarketStatsList) => {
    switch (userSelections.timeframe) {
      case 1:
        return value === "volume" ? token.tokenStats.oneHourVolumeUsd : token.tokenStats.oneHourChange;
      case 4:
        return value === "volume" ? token.tokenStats.fourHourVolumeUsd : token.tokenStats.fourHourChange;
      case 12:
        return value === "volume" ? token.tokenStats.twelveHourVolumeUsd : token.tokenStats.twelveHourChange;
      default:
        return value === "volume" ? token.tokenStats.oneDayVolumeUsd : token.tokenStats.oneDayChange;
    }
  }

  const columns: TableColumn<MarketStatsList>[] = [
    {
      id: "token",
      label: showTokensOnly ? "Token" : "Market",
      valueRenderer: d => {
        const tokenSymbol = d.market.pair.baseToken.symbol
        const currencyToken = isWethToken(d.market.quoteTokenAddress) ? "ETH" : d.market.pair.quoteToken.symbol

        return <div className="flex flex-row items-center gap-3">
          <div className="relative" id={`market_${d.market.baseTokenAddress}_${d.market.chainId}`}>
            <img
              src={showTokensOnly && d.market.pair.baseToken.symbol === "USDB" ? "https://wasabi-public.s3.amazonaws.com/tokens/usdb.png" : d.market.pair.baseToken.imageUrl}
              alt={d.market.pair.baseToken.symbol}
              className="w-6 h-6 rounded-full border border-glass-focus"
            />
            {
              displayChainSelector &&
              <div className="absolute right-[-8px] bottom-[-8px]">
                <img
                  id={`chain_${d.market.pair.baseToken.address}`}
                  src={getChainImage(d.market.chainId)}
                  alt={getChainName(d.market.chainId)}
                  className="w-4 h-4 rounded-full p-0.5"/>
              </div>
            }
          </div>
          {
            displayChainSelector &&
            <ReactTooltip
              anchorSelect={`#market_${d.market.baseTokenAddress}_${d.market.chainId}`}
              id={`tooltip_market_${d.market.baseTokenAddress}_${d.market.chainId}`}
              noArrow
              content={`Available on ${getChainName(d.market.chainId)}`}
              className="z-50 p-2 flex flex-row items-center gap-2"
              style={{
                backgroundColor: "#3b485f",
                color: "white"
              }} />
          }
          <div className="flex flex-row gap-3 items-center">
            <span className="text-xs lg:text-base">
              {
                showTokensOnly
                  ? tokenSymbol :
                  <span className="responsive-flex md:items-center md:gap-1">
                    <span className="text-base md:text-xs">{tokenSymbol}</span>
                    <span
                      className="text-neutral-content text-xs">{(isDesktop ? " / " : "") + currencyToken}</span>
                  </span>
              }
            </span>
          </div>
        </div>;
      },
      span: 3,
      align: "left",
      // sort: stringSort((d) => d.token.symbol),
      columnOnClick: (d) => handleSelection('tokenSearchSort', d)
    },
    {
      id: "rewards",
      label: "Rewards",
      valueRenderer: d =>
        !showTokensOnly &&
        <TradeRewardsView market={d.market} boost={d.boost} />
    },
    {
      id: "marketCap",
      label: isDesktop ? "Market Cap" : <div className="flex flex-col">
        <span>Market Cap</span>
        <span className="text-xs">{userSelections.timeframe}h Volume</span>
      </div>,
      valueRenderer: d => <div className="flex flex-col items-end text-sm">
        <span className="flex flex-row items-center">
          <span className="">$</span>{prettifyNumber(d.tokenStats.marketCap)}
        </span>
        { !isDesktop && <span className="text-neutral-content text-xs">${prettifyNumber(getValue("volume", d))}</span> }
      </div>,
      align: "right",
      hideOnMobile: true,
      span: 2,
      sort: numberSort(d => d.tokenStats.marketCap),
    },
    {
      id: 'openInterest',
      label: 'Open Interest',
      valueRenderer: d =>
        <MarketValueView
          market={d.market}
          className="text-sm"
          value={parseUnits((d.openInterest.longOpenInterest.amount + d.openInterest.shortOpenInterest.amount).toString(), d.market.pair.quoteToken.decimals)}/>,
      align: "right",
      sort: numberSort(d => d.openInterest.longOpenInterest.amount + d.openInterest.shortOpenInterest.amount),
      hideOnMobile: true,
      span: 2,
    },
    {
      id: `${userSelections.timeframe}hVolume`,
      label: `${userSelections.timeframe}h Volume`,
      valueRenderer: d =>
        <span className="flex flex-row items-center text-sm">
          <span className="">$</span>{prettifyNumber(getValue("volume", d))}
        </span>,
      align: "right",
      sort: numberSort(d => getValue("volume", d)),
      columnOnClick: (d) => handleSelection('tokenSearchSort', d),
      span: 2,
    },
    {
      id: `${userSelections.timeframe}hChange`,
      label: `${userSelections.timeframe}h Change`,
      valueRenderer: d => {
        const value = getValue("change", d);
        return <div className={classNames("text-sm flex items-center", {
          "text-put": value < 0,
          "text-call": value > 0,
          "text-neutral-content": value === 0,
        })}>
          {value > 0 && "+"}
          {value.toFixed(2)}%
        </div>
      },
      align: "right",
      sort: numberSort(d => getValue("change", d)),
      columnOnClick: (d) => handleSelection('tokenSearchSort', d),
      hideOnMobile: true,
      span: 2,
    },
    {
      id: "price",
      label: isDesktop ? "Price" : <div className="flex flex-col">
        <span>Price</span>
        <span className="text-xs">{userSelections.timeframe}h %</span>
      </div>,
      valueRenderer: d => {
        const value = getValue("change", d);
        return (
          <div className="flex flex-col items-end">
            <MarketPriceView
              market={d.market}
              className="text-sm"
              iconSize={12}
              price={showTokensOnly && d.market.pair.baseToken.symbol === "USDB" ? 1 : Number(d.tokenStats.price)}
            />
            {
              !isDesktop &&
              <div className={classNames("text-sm flex items-center", {
                "text-put": value < 0,
                "text-call": value > 0,
                "text-neutral-content": value === 0,
              })}>
                {value > 0 && "+"}
                {value.toFixed(2)}%
              </div>
            }
          </div>
        );
      },
      align: "right",
      sort: numberSort((d) => isDesktop ? Number(d.tokenStats.price) : getValue("change", d)),
      columnOnClick: (d) => handleSelection('tokenSearchSort', d),
      span: 2,
    },
  ];

  return (
    <div className={twMerge("w-full flex-grow flex flex-col pb-safe overflow-hidden", className || '')}>
      <div className="flex flex-row gap-2 p-2">
        <div className="flex-grow flex flex-row items-center rounded-md">
          <ImSearch className="searchbar-icon ml-4 mr-4 cursor-pointer" size={18}/>
          <input
            className="h-10 inline-block cursor-pointer border-none outline-none bg-transparent w-full hover:cursor-text"
            type="text"
            placeholder="Search"
            value={searchText}
            onChange={handleInputChange}
            onPaste={handleInputPaste}
            onKeyUp={(event) => {
              if (event.key === "Enter") {
                handleKeyUp(event);
              }
            }}
            autoComplete="off"
          />
        </div>
        {
          isDesktop &&
          <div className="flex justify-end">
            <Tabs<Timeframe>
              borderlessSelected={true}
              className="min-h-[40px] p-0 w-[300px]"
              options={options} value={userSelections.timeframe}
              onChange={(value) => handleSelection('timeframe', value)}
            />
          </div>
        }
        {
          displayChainSelector &&
          <Dropdown options={selections}
                    onChange={e => setSelectedChain(e)}
                    selected={selectedChain}
                    className="min-w-[160px]"
                    optionsImageClassName="rounded-full"
                    optionsClassName="w-[150px]"
                    itemClassName="text-sm"
          />
        }
      </div>
      <hr className="border-neutral-content/20"/>
      <Table<MarketStatsList>
        id={"tokens_" + showTokensOnly}
        className="flex-grow border-none rounded-none no-scrollbar !bg-transparent overflow-hidden"
        columns={columns.filter(c => columnsToExclude === undefined || !columnsToExclude.includes(c.id))}
        data={filteredOptions}
        // initialSort= {userSelections.tokenSearchSort || defaultSort}
        // initialSortDirection="desc"
        isLoading={query.isLoading}
        rowOnClick={handleRowClick}
      />
      {
        !isDesktop &&
        <Tabs<Timeframe>
          className="min-h-[40px] !border-none"
          optionClassName="text-base"
          options={options} value={userSelections.timeframe}
          onChange={(value) => handleSelection('timeframe', value)}
        />
      }
    </div>
  )
}
