import {twMerge} from "tailwind-merge";
import {FaEthereum} from "react-icons/fa6";
import React from "react";
import {withSubscript} from "@/components/Perps/FractionTokenPrice";
import {Market} from "@/components/Perps/types";
import {isUsdEthMarket, isUsdToken} from "@/util/chainConstants";
import {getZeroesAfterDecimal} from "@/util/converters";

export interface Props {
  price: number;
  market: Market;
  className?: string;
  iconSize?: number;
}

const formatUsdPrice = (price: number) => {
  if (price >= 0.001) {
    let maxFractionDigits;
    if (price > 100) {
      maxFractionDigits = 1;
    } else if (price > 10) {
      maxFractionDigits = 2;
    } else if (price > 1) {
      maxFractionDigits = 3;
    } else if (price > 0.1) {
      maxFractionDigits = 4;
    } else {
      maxFractionDigits = 5;
    }
    return price.toLocaleString([], { style: "currency", currency: "USD", maximumFractionDigits: maxFractionDigits });
  } else {
    const zeroesAfterDecimal = getZeroesAfterDecimal(price);
    const priceToUse = price * Math.pow(10, zeroesAfterDecimal + 3);
    return (
      <span>
        $<span>0.0<sub className="text-[10px]">{zeroesAfterDecimal}</sub>{priceToUse.toFixed(0)}</span>
      </span>
    )
  }
}

export const MarketPriceView = (props: Props) => {
  const {price, market, className, iconSize} = props;
  const isUsdEth = isUsdEthMarket(market);
  const isUsdQuote = isUsdToken(market.quoteTokenAddress);
  let value: string | React.ReactNode;
  const isUsd = isUsdEth || isUsdQuote;

  if (isUsdEth) { // USD/ETH pair
    value = formatUsdPrice(1 / Number(price));
  } else if (isUsdQuote) {
    value = formatUsdPrice(Number(price));
  } else {
    value = withSubscript(Number(price))
  }

  return (
    <span className={twMerge("flex flex-row items-center", className || '')}>
      {isUsd ? "" : <FaEthereum size={iconSize || ''} />}{value}
    </span>
  );
};