import {Boost, Market} from "@/components/Perps/types";
import {OverlapItem, OverlappingImages} from "@/components/Vault/OverlappingImages";
import {BLAST_TOKEN} from "@/util/constants";
import {USD_ADDRESS, WETH_ADDRESS} from "@/util/chainConstants";
import {TimeRemaining} from "@/components/TimeRemaining";
import {blast} from "wagmi/chains";
import {PerpSide} from "@/components/Perps/openPosition/PerpSideSelect";

export interface Props {
  market: Market;
  boost?: Boost;
  side?: PerpSide;
}

export const TradeRewardsView = ({market, boost, side}: Props) => {
  const items: OverlapItem[] = [];
  const isBlast = market.chainId == blast.id;
  if (isBlast) {
    items.push({
      imageUrl: "/static/BlastGoldIcon.svg",
      id: "blast_gold",
      tooltip: "This market earns Blast Gold"
    });
  }
  const boostAmount = boost?.boost || 0;
  items.push({
    imageUrl: "/static/WasabiPointIcon.svg",
    id: "wasabi_points",
    tooltip: (
      <>This market earns {boostAmount ? `${boostAmount}x ` : ""}Wasabi Points
        {(boostAmount > 0 && boost?.end)
          ? (<>for the next <TimeRemaining epochSeconds={boost.end} /></>)
          : ''
        }
      </>
    ),
    boosted: boostAmount > 1
  });
  if (boostAmount > 1) {
    items.push({
      imageUrl: `/static/${boostAmount}x_g.svg`,
      id: "wasabi_points_boost",
      tooltip: (
        <>This market earns {boostAmount ? `${boostAmount}x ` : ""}Wasabi Points
          {(boost?.end)
            ? (<>for the next <TimeRemaining epochSeconds={boost.end} /></>)
            : ''
          }
        </>
      )
    })
  }
  if (isBlast) {
    const earningBlastPoints = market.baseTokenAddress.toLowerCase() === WETH_ADDRESS.toLowerCase()
      || market.baseTokenAddress.toLowerCase() === USD_ADDRESS.toLowerCase()
      || market.baseTokenAddress.toLowerCase() === BLAST_TOKEN.toLowerCase();
    const isDouble = market.baseTokenAddress.toLowerCase() === BLAST_TOKEN.toLowerCase();

    if (earningBlastPoints || side !== "long" ) {
      items.push({
        imageUrl: "/static/BlastPointIcon.svg",
        id: "blast_points",
        tooltip: `${earningBlastPoints ? 'This' : 'Shorting this'} market earns ${isDouble ? "2x " : ""}Blast Points`,
        boosted: isDouble
      });
      if (isDouble) {
        items.push({
          imageUrl: "/static/2x_y.svg",
          id: "blast_points_boost",
          tooltip: "This market earns 2x Blast Points",
        })
      }
    }
  }

  return (
    <OverlappingImages items={items} />
  )
}